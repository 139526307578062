import React from "react";
import leftphoto from "../../images/leftphoto.png";
import rightphoto from "../../images/rightphoto.png";
import orangecircle from "../../images/orangecircle.svg";

const Hero1 = () => {
  const options = [
    { value: 0, display: "Select Solar type.." },
    { value: 1, display: "Solar Panels" },
    { value: 2, display: "Solar Batteries" },
    { value: 3, display: "Live Monitoring" },
    { value: 4, display: "Solar Panels intallation" },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission
  };

  return (
    <div className="hero-banner-ten position-relative zn2">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-9 col-md-10 m-auto text-center"
            data-aos="fade-up"
          >
            <h1 className="hero-heading fw-500 tx-dark">
            Take Control of Your <span>Energy </span>Bill
                     </h1>
            <p className="text-lg tx-dark mt-45 mb-50 lg-mt-30 lg-mb-40">
            Want a Free Solar Estimate?
            </p>
            <form
              className="search-area d-md-inline-flex m-auto"
              onSubmit={handleSubmit}
            >
              <select className="nice-select form-select">
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.display}
                  </option>
                ))}
              </select>
              <input
                type="text"
                className="zip-code-input"
                placeholder="Your Zip code"
              />
              <button className="fw-500 text-white tran3s" type="submit">
                Search
              </button>
            </form>
            {/* End form */}

            <div>
              <div className="approval-info d-inline-flex align-items-center mt-130 lg-mt-80">
                <img src="/images/icon/icon_99.svg" alt="" className="me-1" />
                <span>An USA insurer approved by the UICO.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End .container */}

      <img
      src={leftphoto}
      alt="ilustration"
        className="lazy-img illustration-one"
        data-aos="fade-left"
      />
      <img
        src={rightphoto}
        alt="ilustration"
        className="lazy-img illustration-two"
        data-aos="fade-right"
      />
          <img src={orangecircle} className='orangecircle' alt='orangecircle' />
    </div>
    
  );
};

export default Hero1;
