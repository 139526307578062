import React from "react";
import { Link } from "react-router-dom";
import solar1 from "../images/solar1.webp";
import solar2 from "../images/solar2.jpg";
import solar3 from "../images/solar3.jpg";
import solar4 from "../images/solar4.webp";

const StackyCards = () => {
 

  return (
    <div className="outer-stackycards">
<div className="StackyCards">
  <ul id="cards">
    <li className="card" id="card-1">
      <div className="card-content">
        <div>
        <h2>Solar Fleet Manager</h2>
        <p  className="card2-black">
          Are you curious about how solar energy can benefit your home? Our solar experts are well-versed in the local solar incentives, regulations, and weather conditions that make each state unique. 
          </p>
          <div className="cta1">
          <Link to="/contact" className="my-btn">
              Order Installation
           </Link>
          </div>
        </div>
        <figure>
        <img src={solar1} className="solar1" alt="card-one" />
        
        </figure>
      </div>
    </li>
    <li className="card" id="card-2">
      <div className="card-content">
        <div>
        <h2>Solar PV Installer</h2>
          <p  className="card2-black">
          Are you curious about how solar energy can benefit your home? Our solar experts are well-versed in the local solar incentives, regulations, and weather conditions that make each state unique. 
          </p>
          <div className="cta1">
          <Link to="/contact" className="my-btn">
              Order Installation
           </Link>
          </div>
        </div>
        <figure>
        <img src={solar2} className="solar1" alt="card-one" />
        </figure>
      </div>
    </li>
    <li className="card" id="card-3">
      <div className="card-content">
        <div>
        <h2>Solar Panel Setup</h2>
        <p  className="card2-black">
          Are you curious about how solar energy can benefit your home? Our solar experts are well-versed in the local solar incentives, regulations, and weather conditions that make each state unique. 
          </p>
          <div className="cta1">
          <Link to="/contact" className="my-btn">
              Order Installation
           </Link>
          </div>
        </div>
        <figure>

        <img src={solar3} className="solar1" alt="card-one" />
        </figure>
      </div>
    </li>
    <li className="card" id="card-4">
      <div className="card-content">
        <div>
        <h2 className="card2-heading">Built Up Solar</h2>
        <p  className="card2-black">
          Are you curious about how solar energy can benefit your home? Our solar experts are well-versed in the local solar incentives, regulations, and weather conditions that make each state unique. 
          </p>
          <div className="cta1">
          <Link to="/contact" className="my-btn">
              Order Installation
           </Link>
          </div>
        </div>
        <figure>
        <img src={solar4} className="solar1" alt="card-one" />
        </figure>
      </div>
    </li>
  </ul>
</div>
</div>

  );
};

export default StackyCards;
