import React from "react";
import { Link } from "react-router-dom";
// Import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiperArrows } from "../../lib/hooks/useSwiperArrows";

// images

import b1 from "../../images/b1.png";
import b2 from "../../images/b2.png";
import b3 from "../../images/b3.png";
import b4 from "../../images/b4.png";
// Configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const Whysolar = () => {
    const swiper_settings = {
        spaceBetween: 10,
        slidesPerView: 1.2,
        loop: true,
        autoplay: {
            delay: 3000, // Time between slides in milliseconds
            disableOnInteraction: false, // Keeps autoplay running after user interaction
        },
        speed: 2000, // Transition speed in milliseconds
        breakpoints: {
            500: {
                slidesPerView: 1.4,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2.2,
                spaceBetween: 10,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1200: {
                slidesPerView: 3.4,
                spaceBetween: 10,
            },
            1500: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
        },
    };

    const { sliderRef, toNext, toPrev } = useSwiperArrows();

    return (
        <section className="project-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-4">
                        <div className="project-content">
                            <div className="section-title white-title mb-30">
                                {/* <span className="sub-title">Our Complete Craft</span> */}
                                <h2 className="main-title-black">
                                Top 5 <span class="green"> Benefits </span>of Running Your Home on Solar Power
                                </h2>
                            </div>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard.
                            </p>
                            <div className="content-bottom">
                                <div className="cta1">
                                    <Link to="/contact" className="my-btn">
                                        Order Installation
                                    </Link>
                                </div>

                                <div className="project-nav">
                                    <button
                                        className="swiper-button-prev"
                                        onClick={toPrev}
                                    ></button>
                                    <button
                                        className="swiper-button-next"
                                        onClick={toNext}
                                    ></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-8">
                        <div className="project-item-wrap">
                            <div className="swiper-container project-active">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        {...swiper_settings}
                                        onBeforeInit={(swiper) => {
                                            sliderRef.current = swiper;
                                        }}
                                    >
                                        {/* Define each slide manually */}
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="project-item-three one">
                                                    <div className="project-content-three">
                                                        <h2 className="title">
                                                            <a href="/" style={{ cursor: "none" }}>
                                                                Save 20-100% On Electricity Costs
                                                            </a>
                                                        </h2>
                                                        <p>
                                                            At Collax we specialize in designing, building,
                                                            shipping...
                                                        </p>
                                                    </div>
                                                    <div className="project-thumb-three">
                                                        <a href="/" style={{ cursor: "none" }}>
                                                            <img src={b1} className="ben1" alt="b1" />
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="project-item-three two">
                                                    <div className="project-content-three">
                                                        <h2 className="title">
                                                            <a href="/" style={{ cursor: "none" }}>
                                                                Reduce Air, Water and Land Pollution
                                                            </a>
                                                        </h2>
                                                        <p>
                                                            At Collax we specialize in designing, building,
                                                            shipping...
                                                        </p>
                                                    </div>
                                                    <div className="project-thumb-three">
                                                        <a href="/" style={{ cursor: "none" }}>
                                                            <img src={b2} className="ben1" alt="b1" />
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="project-item-three three">
                                                    <div className="project-content-three">
                                                        <h2 className="title">
                                                            <a href="/" style={{ cursor: "none" }}>
                                                                Increase Your Home’s Value
                                                            </a>
                                                        </h2>
                                                        <p>
                                                            At Collax we specialize in designing, building,
                                                            shipping...
                                                        </p>
                                                    </div>
                                                    <div className="project-thumb-three">
                                                        <a href="/" style={{ cursor: "none" }}>
                                                            <img src={b3} className="ben1" alt="b1" />
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="project-item-three four">
                                                    <div className="project-content-three">
                                                        <h2 className="title">
                                                            <a href="/" style={{ cursor: "none" }}>
                                                                Use Energy Guilt-Free
                                                            </a>
                                                        </h2>
                                                        <p>
                                                            At Collax we specialize in designing, building,
                                                            shipping...
                                                        </p>
                                                    </div>
                                                    <div className="project-thumb-three">
                                                        <a href="/" style={{ cursor: "none" }}>
                                                            <img src={b4} className="ben1" alt="b1" />
                                                        </a>
                                                    </div>
                                                    {/* <div className="project-details-btn">
                                                        <a href="/project-details" style={{ cursor: "none" }}>
                                                            View Case Studies
                                                        </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    );
};

export default Whysolar;
