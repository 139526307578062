import React from 'react'
import { MotionAnimate } from 'react-motion-animate';
import { Parallax } from "react-parallax";
import { Fade } from 'react-awesome-reveal';
import residance from "../../images/residance.webp";
import StackyCards from '../StackingCards';

function Aboutwork() {

    return (
        <section className='Aboutwork '>
            <div className='container'>
                <div className='row text-center'>
                    <div className='col-md-12 col-12'>
                    <div className='Aboutmain'>
                                <h3 className='subtile'>we work with</h3>
                                <h2 className='main-title-black'>We install high-quality <span className='green'>solar panels</span> both in
                                    homes and in large-scale industries</h2>
                            </div>
                   {/* <MotionAnimate
                            delay={0.4}
                            speed={2}
                            ease={[0.75, 0.45, 0.53, 0.94]}
                            reset={true}>
                          
                        </MotionAnimate> */}
                        <div className='row'>
                           <StackyCards />
                      </div>
                    </div>
                </div>
                <div className='row'>
                    <div>


                    </div>
                </div>
            </div>
        </section>
    )
}

export default Aboutwork