import React from 'react'
import { Link } from "react-router-dom";
import videobg from "../../images/videobg.png";
import orangecircle from "../../images/orangecircle.svg";
import herobanner from "../../images/herobanner.webm";

function Bannerimg() {
  return (
<section className='Bannerimg'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
        <h2 className='mai'>Does Your Home Need <br /> Quality<span className='green'> Solar Panel </span>Installation?</h2>
        <p className='solution'>Our solar power experts will help you find the perfect solution for your home.</p>

        <div className="cta2">
          <Link to="/contact" className="my-btn">
          Get a Free Consultation
           </Link>
          </div>
            </div>
        
    </div>
    </div>
 
    <div className='bnrvideo'>
    <img src={videobg} className='bnrimg2' alt='bnrimg' />
     <video src={herobanner} autoPlay loop muted />
    </div>
    <img src={orangecircle} className='orangecircle' alt='orangecircle' />
    
</section>
  )
}

export default Bannerimg