import residance from "../../images/residance.webp";
import Commercial from "../../images/Commercial.webp";
import cost from "../../images/cost.png";
import inst from "../../images/inst.png";

import solarcell from "../../images/solarcell.png";
import React, { useEffect } from 'react';
import { MotionAnimate } from 'react-motion-animate';



function Solarcatagries() {

  return (
    <section className='benefits'>
      <div className='container'>
        <div className="row" id="environment">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
          <h3 className='subtile'>Why Choose Us</h3>
            <h2 className='main-title-black'>Build a healthy environment with the use of <span className='green'> Solar Panel </span></h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <p>Lorem ipsum dolor sit amet consectetur. Ornare vel vulputate ante condimentum ut mauris pellentesque. Pretium ornare consequat et gravida ultricies tellus sociis. Venenatis tempor nunc habitasse.Lorem ipsum dolor sit amet consectetur. Ornare vel vulputate ante condimentum ut mauris pellentesque. Pretium ornare consequat et gravida ultricies tellus sociis. Venenatis tempor nunc habitasse.</p>
          </div>
        </div>
        <div className='row'>
        <div className='col-xxl-3 col-xl-3 col-md-6 col-12'>
        <div className=" Expert">
        <img src={solarcell} className="solarcell" alt="solarcell" />
          <h3>Expert Installation</h3>
          <p>We offer professional solar panel installation services .Team of experienced technicians will ensure it is installed correctly.</p>
        </div>
      
        </div>
        <div className='col-xxl-3 col-xl-3 col-md-6 col-12'>
        <div className=" Expert">
        <img src={cost} className="solarcell" alt="solarcell" />
          <h3>Low Cost operation</h3>
          <p>We offer professional solar panel installation services .Team of experienced technicians will ensure it is installed correctly.</p>
        </div>
      
        </div>
        <div className='col-xxl-3 col-xl-3 col-md-6 col-12'>
        <div className=" Expert">
        <img src={inst} className="solarcell" alt="solarcell" />
          <h3>Modern Technology</h3>
          <p>We offer professional solar panel installation services .Team of experienced technicians will ensure it is installed correctly.</p>
        </div>
      
        </div>
        <div className='col-xxl-3 col-xl-3 col-md-6 col-12'>
        <div className=" Expert">
        <img src={solarcell} className="solarcell" alt="solarcell" />
          <h3>Expert Installation</h3>
          <p>We offer professional solar panel installation services .Team of experienced technicians will ensure it is installed correctly.</p>
        </div>
      
        </div>
        </div>
        {/* <div className='row'>
       
          <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
       <div className="outerimg">
              <img src={residance} alt={"image"} className="second-img" />
              <div className="consectetur-content">
                <h3>House</h3>
              </div>
      </div>

          </div>
          <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
          <div className="outerimg">
              <img src={Commercial} alt={"image"} className="second-img" />
              <div className="consectetur-content">
                <h3>Commercial facilities</h3>
              </div>
              </div>
          </div>

        </div> */}
      </div>
    </section>
  )
}

export default Solarcatagries