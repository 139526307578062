import React from "react";
import AboutArea from "../../components/About/AboutArea";
import BannerOne from "../../components/Banner/BannerOne";
import BlogArea from "../../components/Blog/BlogArea";
import ConsultationArea from "../../components/Consultation/ConsultationArea";
import NewsLetterArea from "../../components/NewsLetter/NewsLetterArea";
import ProjectArea from "../../components/Project/ProjectArea";
import ServicesArea from "../../components/ServicesArea/ServicesArea";
import TeamArea from "../../components/Team/TeamArea";
import TestimonialArea from "../../components/Testimonial/TestimonialArea";
import Layout from "../../layouts/Layout";
import herobanner from "../../images/herobanner.webm";
import About3 from "../../components/about3";
import StackyCards from "../../components/StackingCards";
import AdvancedBannerTop from "../../components/AdvancedBannerTop/AdvancedBannerTop";
import Aboutwork from "../../components/home component/Aboutwork";
import Aboutimage from "../../components/home component/Aboutimage";
import Whyus from "../../components/home component/Whyus";
import Bannerimg from "../../components/home component/Bannerimg";
import Solarcatagries from "../../components/home component/Solarcatagries";
import Advantages from "../../components/home component/Advantages";
import Aboutus from "./Aboutus";
import Mybanner from "./Mybanner";
import ProjectAreaThree from "../../components/Project/ProjectAreaThree";
import Whysolar from "../../components/home component/Whysolar";
import Hero1 from "../../components/home component/Hero";
import Faq from "../../components/home component/Faq";

const CreativeAgency = () => {
  return (
    <Layout header={1} footer={1}>
    <Hero1 />
      {/* <Bannerimg /> */}

      {/* <section className='latestbnr'>
        <div className='video-container'>
          <video src={herobanner} autoPlay loop muted />
          <div className='overlay' />
        </div>
        <div className='contec'>
          <h2>Produce Your Own Clean<br /><span className='lightspan'> Energy to Save the Environment</span></h2>
          <div class="form-btn">
       
          </div>
        </div>
      </section> */}
      <Aboutwork />
      <Solarcatagries />
      <Whysolar />
      {/* <Mybanner /> */}
      {/* <ProjectArea /> */}
      {/* <ProjectAreaThree /> */}
      {/* <Aboutus /> */}


      {/* <Aboutimage /> */}
      <div className="fancy-feature-fortyOne position-relative mt-160 lg-mt-100">
        <div className="container">
        <div className="row">
        <div className="col-xl-6 col-lg-6" data-aos="fade-up">
        <div className="title-style-one mb-40 pt-30 lg-pt-10 lg-mb-20">
              <h3 className='subtile'>we work with</h3>
                <h2 class="main-title-black">
               Do you have any question in your mind?
                </h2>
              </div>
        </div>
        </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6" data-aos="fade-right">
             <Faq />
              <a href="#" className="btn-one fw-500 mt-50 md-mt-40">
                Explorer All
              </a>
            </div>
                 <div
              className="col-xxl-6 col-xl-6 col-lg-6 ms-auto"
              data-aos="fade-left"
            >
              <div className="wrapper position-relative ps-sm-3 pe-sm-3 ps-xl-5 pe-xl-5 md-mt-70">
                <div className="row">
                <Faq />
                </div>
              
              </div>
     
            </div>
   
          </div>
        </div>
      </div>
      {/* <AboutArea />
      <About3 /> */}
      {/* <ServicesArea /> */}
      {/* <Advantages /> */}


      {/* <Whyus />
      <AdvancedBannerTop /> */}


      {/* <TeamArea /> */}

      {/* <TestimonialArea />
      <ConsultationArea /> */}
      {/* <BlogArea /> */}
      {/* <NewsLetterArea /> */}
    </Layout>
  );
};

export default CreativeAgency;
